import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { tryit } from "@bsgp/lib-core";

import { Button } from "@ui5/webcomponents-react";

import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign.js";

function HomeButton(props) {
  const {
    history,
    location,
    is_authenticated,
    showHeaderButtonText,
    showHomeButton,
    theme
  } = props;

  if (!is_authenticated) {
    return false;
  }

  if (showHomeButton !== true) {
    return false;
  }

  return (
    <div slot={props.slot}>
      <Button
        id={"homeBtn"}
        icon="home"
        className={theme}
        design={ButtonDesign.Transparent}
        title="Go Home"
        onClick={() => {
          if (/^\/.{0}$/.test(location.pathname)) {
            console.warn("It's already home page");
          } else {
            history.push("/");
          }
        }}
      >
        {showHeaderButtonText ? "홈" : undefined}
      </Button>
    </div>
  );
}

function mapStateToProps(state) {
  const config = tryit(() => state["api-Configuration"].merged.ui.result) || {};
  return {
    showHeaderButtonText: config.ShowHeaderButtonText,
    showHomeButton: config.ShowHomeButton,
    is_authenticated: state.user.currentUser.is_authenticated,
    paths: state.user.currentUser.paths || [],
    theme: state.user.currentUser.theme
  };
}

export default withRouter(connect(mapStateToProps)(HomeButton));
