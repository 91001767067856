var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var dashboard_exports = {};
__export(dashboard_exports, {
  default: () => dashboard_default,
  embedOnDom: () => embedOnDom,
  getEmbedURL: () => getEmbedURL
});
module.exports = __toCommonJS(dashboard_exports);
var import_react = __toESM(require("react"));
var import_react_router_dom = require("react-router-dom");
var import_toolkit = require("@reduxjs/toolkit");
var import_lib_hooks = require("@bsgp/lib-hooks");
var import_reducers = require("./reducers");
var actions = __toESM(require("./actions"));
var import_context = require("./context");
var import_main = __toESM(require("./main"));
function Dashboard(props) {
  const { match, history, qsConfig } = props;
  const { path, params } = match;
  const { location } = history;
  const queryParams = new URLSearchParams(location.search);
  const [_state, _dispatch] = (0, import_lib_hooks.useReducerWithLogger)(import_reducers.reducer, import_reducers.initialState);
  const newQsConfig = (0, import_react.useMemo)(
    () => __spreadValues({
      id: params.qsAlias,
      sessionId: queryParams.get("session"),
      type: "EmbedDashboard"
    }, qsConfig),
    []
  );
  return /* @__PURE__ */ import_react.default.createElement(
    import_context.context.Provider,
    {
      value: {
        _state,
        _dispatch
      }
    },
    /* @__PURE__ */ import_react.default.createElement(import_react_router_dom.Switch, null, /* @__PURE__ */ import_react.default.createElement(
      import_react_router_dom.Route,
      {
        exact: true,
        path: `${path}`,
        render: (rProps) => /* @__PURE__ */ import_react.default.createElement(
          import_main.default,
          __spreadValues(__spreadProps(__spreadValues(__spreadValues({}, rProps), props), {
            title: _state.title || props.title
          }), {
            _state,
            _dispatch,
            qsConfig: newQsConfig
          })
        )
      }
    ))
  );
}
var dashboard_default = (0, import_toolkit.compose)(import_react_router_dom.withRouter)(Dashboard);
const { getEmbedURL, embedOnDom } = actions;
