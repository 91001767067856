var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var reducers_exports = {};
__export(reducers_exports, {
  initialState: () => initialState,
  reducer: () => reducer
});
module.exports = __toCommonJS(reducers_exports);
var import_immer = __toESM(require("immer"));
var import_actions = require("./actions");
const initialState = {
  embedURL: "",
  title: "",
  parameters: []
};
const reducer = function(state = initialState, action) {
  return (0, import_immer.default)(state, (draft) => {
    switch (action.type) {
      case import_actions.SET_EMBED_URL:
        draft.embedURL = action.embedURL;
        draft.title = action.title;
        draft.parameters = action.parameters;
        draft.changes = action.changes;
        break;
      default:
        break;
    }
  });
};
