import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

import {
  Link,
  ResponsivePopover,
  Input,
  FlexBox,
  Timeline,
  TimelineItem,
  BusyIndicator,
  Button,
  Text,
  Select,
  CheckBox,
  Option,
  Menu,
  MenuItem,
  MessageStrip
} from "@ui5/webcomponents-react";
import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign.js";
import { apiHub, s3 } from "@bsgp/lib-api";
import { makeid, tryit } from "@bsgp/lib-core";
import { LiveAudioVisualizer } from "react-audio-visualize";

import { setDataFromAi, setAiSessionId,
  setAiMessages
} from "actions/user";
import { addError } from "actions/ui5";
import "@ui5/webcomponents-icons-tnt/dist/robot.js";

function getMediaRecorder() {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    console.log("getUserMedia supported.");

    return navigator.mediaDevices
      .getUserMedia(
        // constraints - only audio needed for this app
        {
          audio: true
        }
      )
      .then(stream => {
        // Success callback

        // const audioCtx = new (window.AudioContext ||
        //   window.webkitAudioContext)();
        // audioCtx.createMediaStreamSource(stream);
        // console.log("audioCtx.sampleRate", audioCtx.sampleRate);
        // setSampleRate(audioCtx.sampleRate);

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: "audio/mp4" // "audio/ogg;codecs=opus"
        });
        return mediaRecorder;
      })
      .catch(err => {
        // Error callback
        console.error(`The following getUserMedia error occurred: ${err}`);
        console.log(err.name, err.message);
        if (err.name === "NotAllowedError") {
          alert("Please allow microphone access");
        }
      });
  } else {
    console.log("getUserMedia not supported on your browser!");
  }
}

const scrollToBottom = messagesEndRef => {
  console.log("scrollToBottom");
  setTimeout(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // if (messagesEndRef.current) {
  //   messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  // }
  }, 100);
};

const extractText = content => {
  const contentObj = tryit(() => JSON.parse(content));
  const result = contentObj
    ? contentObj.find(each => each.type === "text")?.text
    : content;
  return result;
};

const extractImageUrl = content => {
  const contentObj = tryit(() => JSON.parse(content));
  const result = contentObj &&
    contentObj.find(each => each.type === "image_url")
      ?.image_url?.url;
  return result;
};

const reLang = /language-(\w+)/;

function Audio(props) {
  const { currentUser, dispatch, history } = props;

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  // useEffect(() => {
  //   const containers = [
  //     "webm",
  //     "ogg",
  //     "mp4",
  //     "x-matroska",
  //     "3gpp",
  //     "3gpp2",
  //     "3gp2",
  //     "quicktime",
  //     "mpeg",
  //     "aac",
  //     "flac",
  //     "wav"
  //   ];
  //   const codecs = [
  //     "vp9",
  //     "vp8",
  //     "avc1",
  //     "av1",
  //     "h265",
  //     "h.265",
  //     "h264",
  //     "h.264",
  //     "opus",
  //     "pcm",
  //     "aac",
  //     "mpeg",
  //     "mp4a"
  //   ];

  //   const supportedAudios = containers
  //     .map(format => `audio/${format}`)
  //     .filter(mimeType => MediaRecorder.isTypeSupported(mimeType));
  //   const supportedAudioCodecs = supportedAudios
  //     .flatMap(audio => codecs.map(codec => `${audio};codecs=${codec}`))
  //     .filter(mimeType => MediaRecorder.isTypeSupported(mimeType));

  //   console.log("Supported Audio formats:", supportedAudios);
  //   console.log("Supported Audio codecs:", supportedAudioCodecs);

  //   const supportedVideos = containers
  //     .map(format => `video/${format}`)
  //     .filter(mimeType => MediaRecorder.isTypeSupported(mimeType));
  //   const supportedVideoCodecs = supportedVideos
  //     .flatMap(video => codecs.map(codec => `${video};codecs=${codec}`))
  //     .filter(mimeType => MediaRecorder.isTypeSupported(mimeType));

  //   console.log("Supported Video formats:", supportedVideos);
  //   console.log("Supported Video codecs:", supportedVideoCodecs);
  // }, []);

  return (
    <div slot={props.slot} style={{ display: "inline-block" }}>
      <Button
        id={"openRecorderBtn"}
        className={[currentUser.theme].join(" ")}
        icon="bsg/ai"
        onClick={() => {
          setDialogIsOpen(!dialogIsOpen);
        }}
      />
      {createPortal(
        <ResponsivePopover
          opener={"openRecorderBtn"}
          open={dialogIsOpen}
          className="chatbot-dialog"
          onClose={() => {
            setDialogIsOpen(false);
          }}
        >
          <Chatbot
            dispatch={dispatch}
            history={history}
            containerIsOpen={dialogIsOpen}
            currentUser={currentUser}
          />
        </ResponsivePopover>,
        document.body
      )}
    </div>
  );
}

function Chatbot(props) {
  const { dispatch, history, containerIsOpen, currentUser } = props;

  const messagesEndRef = useRef(null);

  const questionRef = useRef();

  const [mediaRecorder, setMediaRecorder] = useState();
  const [, setIsRecording] = useState(false);
  const [, setBlob] = useState();
  const [models, setModels] = useState({});
  const modelsArr = Object.keys(models);
  const [altModel, setAltModel] = useState("");
  const [thisModel, setModel] = useState("");
  const [question, setQuestion] = useState("");
  const [isFunctionCall, setIsFunctionCall] = useState(false);
  const [isRag, setIsRag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [aiSettingsOpen, setAiSettingsOpen] = useState(false);
  const [previewImageDialog, setPreviewImageDialog] = useState({
    isOpen: false, opener: "inputQuestion"
  });
  const [fileBase64, setFileBase64] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isFileUploading, setIsFileUploading] = useState(false);

  const { aiSessionId, aiMessages } = currentUser;
  const messages = aiMessages || [];
  const msgCount = messages.length;

  const { lastQuestionContent, lastQuestionIdx, lastModel,
    sumTokensTotal } =
  messages.reduce((acc, msg, idx) => {
    if (msg.role === "user") {
      acc.lastQuestionContent = extractText(msg.content);

      acc.lastQuestionIdx = idx;
    } else if (msg.role === "assistant") {
      acc.lastModel = msg.alias || msg.model;
      acc.sumTokensTotal += msg.tokensTotal;
    }
    return acc;
  }, {
    lastQuestionContent: "",
    lastQuestionIdx: -1,
    sumTokensTotal: 0,
    lastModel: ""
  });

  useEffect(() => {
    const modelKeys = Object.keys(models);
    setAltModel(modelKeys
      .filter(each => each !== lastModel)[0]);
    setModel(lastModel || modelKeys[0]);
  }, [lastModel, models]);

  const queryHistory = async sessionId => {
    if (!sessionId) {
      return;
    }

    setIsLoading(true);
    const msgList = await apiHub.get(
      "/g/genai/history",
      {
        defId: "all",
        sessionId,
        getModels: true
      },
      {},
      { appIsBusy: false }
    );

    setIsLoading(false);
    dispatch(setAiMessages(msgList.data.cbData.list));
    const enabledModels = msgList.data.cbData.result.models;
    if (enabledModels) {
      setModels(enabledModels);
    }
  };

  const onStartNewSession = () => {
    dispatch(setAiSessionId(makeid(32)));
  };

  const invokeAi = async (question, options = {}) => {
    if (!question) {
      return;
    }
    const { model, image, sessionId } = options;

    if (!sessionId) {
      return;
    }

    const textResult = await apiHub.runFlow(
      {
        stage: process.env.REACT_APP_API_STAGE_NAME,
        path: "/g/genai/topics",
        method: "POST",
        payload: {
          question,
          image,
          model,
          defId: "all",
          isFunctionCall,
          isRag,
          sessionId
        }
      }
    );
    console.log("textResult:", textResult);

    if (textResult.data.cbData.body.result.errorMessage) {
      dispatch(addError(textResult.data.cbData.body.result.errorMessage));
      return;
    }
    dispatch(setAiMessages(messages
      .concat(textResult.data.cbData.body.result.messages)));
    setPreviewImageDialog({ isOpen: false, opener: "inputQuestion" });
    setFileBase64("");
    const enabledModels = textResult.data.cbData.body.result.models;
    if (enabledModels) {
      setModels(enabledModels);
      setAltModel(Object.keys(enabledModels)
        .filter(each => each !== lastModel)[0]);
    }
    setQuestion("");
    if (textResult.data.cbData.body.result.toolCalled) {
      if (textResult.data.cbData.body.result.apiResult) {
        dispatch(setDataFromAi(textResult.data.cbData.body.result.apiResult));
      }
      if (textResult.data.cbData.body.result.fmArg.redirectTo) {
        history.push(textResult.data.cbData.body.result.fmArg.redirectTo.url);
      }
    }
  };

  const onChangeModel = event => {
    const model = event.target.selectedOption.dataset.model;
    setAltModel(model);
  };
  const onClickSendAgain = () => {
    invokeAi(lastQuestionContent,
      { model: altModel, image: fileUrl, sessionId: aiSessionId });
  };

  const startRecording = () => {
    mediaRecorder.start();
    setIsRecording(true);
    setBlob(null);
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    setIsRecording(false);
    setQuestion("");
  };

  useEffect(() => {
    if (!aiSessionId) {
      dispatch(setAiMessages([]));
    } else {
      if (msgCount === 0) {
        queryHistory(aiSessionId);
      } else {
        setTimeout(() => {
          dispatch(setAiMessages([]));
        }, 0);
      }
    }
  }, [aiSessionId]);

  useEffect(() => {
    if (!aiSessionId) {
      dispatch(setAiSessionId(makeid(32)));
    }

    return function cleanup() {
      stopRecording();
    };
  }, []);

  useEffect(() => {
    if (!fileBase64) {
      return;
    }

    setIsFileUploading(true);

    const [contentType, contentBody] = fileBase64.split(",");

    s3.upload({
      data: contentBody,
      contentEncoding: "base64",
      contentType: contentType.replace("data:", "").replace(";base64", ""),
      configKey: "ai_files",
      appIsBusy: false,
      pathVariables: {
        stage: process.env.REACT_APP_API_STAGE_NAME,
        systemId: currentUser.systemID,
        partnerId: currentUser.partnerID,
        userId: currentUser.id,
        fileName: makeid(10) + fileName
      }
    }).then(data => {
      const url = "https://download.bsg.support/" + data.key;
      setFileUrl(url);
      setIsFileUploading(false);
    }
    );
  }, [fileBase64]);

  useEffect(() => {
    if (!mediaRecorder) {
      return;
    }

    startRecording();

    let chunks = [];

    mediaRecorder.ondataavailable = evt => {
      chunks.push(evt.data);
    };
    mediaRecorder.onstop = () => {
      console.log("data available after MediaRecorder.stop() called.");
      setIsRecording(false);

      mediaRecorder.stream
        .getTracks() // get all tracks from the MediaStream
        .forEach(track => track.stop()); // stop each of them

      if (chunks.length === 0) {
        return;
      }

      const blob = new Blob(chunks, {
        type: "audio/mp4" // "audio/ogg;codecs=opus"
      });

      chunks = [];
      setBlob(blob);

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result; // .split(",")[1];
        invokeAi(base64String,
          { model: thisModel, image: fileUrl, sessionId: aiSessionId });
      };
    };

    setTimeout(() => {
      // stopRecording();
    }, 5000);
  }, [mediaRecorder]);

  useEffect(() => {
    scrollToBottom(messagesEndRef);
  }, [containerIsOpen, messages]);

  useEffect(() => {
    function onEnter(event) {
      if (event.key === "Enter") {
        invokeAi(event.target.value,
          { model: thisModel, image: fileUrl, sessionId: aiSessionId });
      }
    }
    if (questionRef.current) {
      questionRef.current.addEventListener("keyup", onEnter, false);
    }
    return function cleanup() {
      if (questionRef.current) {
        questionRef.current.removeEventListener("keyup", onEnter, false);
      }
    };
  }, [messages, isFunctionCall, isRag,
    aiSessionId, thisModel, fileUrl]);

  return (
    <BusyIndicator active={isLoading} delay={0} text={"Loading"}>
      <FlexBox direction="Column" id="chatbot">
        <MessageStrip hideCloseButton={true} style={{ margin: "0.5rem" }}>
          {"본 챗봇에서 발생한 모든 질문과 답변은 서버에 저장되며, 향후 개선을 위해 사용될 수 있습니다"}
        </MessageStrip>
        <FlexBox direction="Column" className="chat-history">
          <Timeline>
            {messages.map((message, msgIdx) => {
              if (message.role === "user") {
                const contentStr = extractText(message.content);
                const contentImage = extractImageUrl(message.content);

                return (
                  <TimelineItem
                    key={msgIdx}
                    name="You"
                    subtitleText={[message.timestamp,
                      "(", message.tokensIn, "tokens", ")"
                    ].join(" ")}
                    icon="person-placeholder"
                  >
                    <div className="content">
                      <Text>{contentStr}</Text>
                      {contentImage && (
                        <a href={contentImage} target="_blank" rel="noreferrer">
                          <img
                            src={contentImage}
                            alt="Image"
                            href
                            style={{ width: "10rem" }}
                          />
                        </a>
                      )}
                    </div>
                    {(msgIdx === lastQuestionIdx && modelsArr.length > 0) && (
                      <FlexBox direction="row" className="bottom">
                        <Select onChange={onChangeModel} value={altModel}>
                          {modelsArr.filter(each => each !== lastModel)
                            .map((model, modIdx) => {
                              return (
                                <Option key={modIdx} data-model={model}>
                                  {model}
                                </Option>
                              );
                            })}
                        </Select>
                        <Link onClick={onClickSendAgain}>Ask Again</Link>
                      </FlexBox>
                    )}
                  </TimelineItem>
                );
              } else {
                return (
                  <TimelineItem
                    key={msgIdx}
                    name="Assistant"
                    // titleText={`(${message.alias || message.model})`}
                    subtitleText={[message.timestamp,
                      "(", message.tokensOut, "tokens", ")"
                    ].join(" ") +
                    (msgIdx === msgCount - 1
                      ? (" " + ["Total:", sumTokensTotal, "tokens"].join(" "))
                      : "")}
                    icon="tnt/robot"
                  >
                    <div className="content">
                      <div className="modelName">
                        <Text>{message.alias || message.model}:</Text>
                      </div>
                      <Markdown
                        components={{
                          code({ node, inline, className, children,
                            ...props }) {
                            const match = reLang.exec(className || "");
                            return !inline && match
                              ? (
                                  <SyntaxHighlighter
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                  >{String(children).replace(/\n$/, "")}
                                  </SyntaxHighlighter>
                                )
                              : (
                                  <code className={className} {...props}>
                                    {children}
                                  </code>
                                );
                          }
                        }}
                      >{message.content}</Markdown>
                    </div>
                  </TimelineItem>
                );
              }
            })}
          </Timeline>
          {msgCount > 0 && (
            <Link
              className={"new_session"}
              style={{
                // visibility: "hidden" 
              }}
              ref={messagesEndRef}
              // design={LinkDesign.Subtle}
              onClick={onStartNewSession}
            >
              New Session
            </Link>
          )}
        </FlexBox>

        <FlexBox direction="Column" className="chat-bottom-container">
          <FlexBox direction="Row" className="chat-inputs-container">
            <FlexBox direction="Row" style={{ alignItems: "center" }}>
              {mediaRecorder?.state === "recording" ? (
                <span style={{ position: "relative" }}>
                  <LiveAudioVisualizer
                    mediaRecorder={mediaRecorder}
                    barColor={"lightblue"}
                  />
                </span>
              ) : (
                <span style={{ position: "relative" }}>
                  <Input
                    id="inputQuestion"
                    onChange={event => {
                      setQuestion(event.target.value);
                    }}
                    placeholder="Type your question or drop image here"
                    ref={questionRef}
                    value={question}
                    onDrop={event => {
                      event.preventDefault();
                      const file = event.dataTransfer.files[0];
                      if (file && file.type.startsWith("image/")) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setFileBase64(base64String);
                          setFileName(file.name);
                          setPreviewImageDialog({
                            isOpen: true, opener: "inputQuestion"
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                  {createPortal(
                    <ResponsivePopover
                      opener={previewImageDialog.opener}
                      open={previewImageDialog.isOpen}
                      className="chatbot-preview-image"
                      modal={isFileUploading}
                      onClose={() => {
                        setPreviewImageDialog({
                          isOpen: false, opener: "inputQuestion"
                        });
                      }}
                    >
                      <BusyIndicator
                        active={isFileUploading}
                        delay={0}
                        text="Uploading"
                      >
                        <FlexBox direction="Column">
                          <img src={fileBase64} alt="Image" />
                          <Button
                            design={ButtonDesign.Transparent}
                            onClick={() => {
                              setFileBase64("");
                              setPreviewImageDialog({
                                isOpen: false, opener: "inputQuestion"
                              });
                            }}
                          >Clear</Button>
                        </FlexBox>
                      </BusyIndicator>
                    </ResponsivePopover>,
                    document.body
                  )}
                </span>
              )}
            </FlexBox>
            <FlexBox direction="Row" style={{ alignItems: "center" }}>
              {mediaRecorder?.state === "recording" ? (
                <Button
                  className="stop"
                  icon="stop"
                  onClick={() => {
                    stopRecording();
                  }}
                ></Button>
              ) : (
                <Button
                  className="microphone"
                  icon="microphone"
                  onClick={() => {
                    getMediaRecorder().then(mediaRecorder => {
                      setMediaRecorder(mediaRecorder);
                    });
                  }}
                ></Button>
              )}
            </FlexBox>
            <FlexBox direction="Row" style={{ alignItems: "center" }}>
              {fileBase64 && (
                <img
                  id="imgPreview"
                  src={fileBase64}
                  alt="Image"
                  style={{
                    width: "1.5rem", height: "1.5rem", border: "1px solid",
                    marginLeft: "0.1rem", cursor: "pointer", padding: "2px"
                  }}
                  onClick={() => {
                    setPreviewImageDialog({
                      isOpen: true, opener: "imgPreview"
                    });
                  }}
                />
              )}
            </FlexBox>
          </FlexBox>
          <FlexBox
            direction="Row"
            className="chat-control-container"
            alignItems="Center"
          >
            <Button
              className="send"
              design={ButtonDesign.Emphasized}
              icon="paper-plane"
              onClick={() => {
                invokeAi(question,
                  { model: thisModel, image: fileUrl, sessionId: aiSessionId });
              }}
            >
              Send
            </Button>
            <Button
              id="btnAiSettings"
              icon="settings"
              onClick={() => {
                setAiSettingsOpen(!aiSettingsOpen);
              }}
              design={ButtonDesign.Transparent}
            >{[thisModel, isFunctionCall ? "(Function Call)" : ""]
                .filter(Boolean)
                .join(" ")}</Button>
            <Menu
              opener="btnAiSettings"
              open={aiSettingsOpen}
              onClose={() => {
                setAiSettingsOpen(false);
              }}
              onItemClick={event => {
                const { funcCall, selectedModel } = event.detail.item.dataset;
                if (selectedModel !== undefined) {
                  setIsFunctionCall(funcCall === "true");
                  setModel(selectedModel);
                }
              }}
            >
              {/* <MenuItem text="Function Call">
                <MenuItem
                  text="On"
                  data-func-call={true}
                  icon={isFunctionCall ? "accept" : ""}
                />
                <MenuItem
                  text="Off"
                  data-func-call={false}
                  icon={isFunctionCall ? "" : "accept"}
                />
              </MenuItem> */}
              {/* <MenuItem text="Model"> */}
              {modelsArr.reduce((acc, model, modIdx) => {
                acc.push(
                  <MenuItem
                    key={modIdx}
                    data-selected-model={model}
                    data-func-call={false}
                    icon={(model === thisModel && !isFunctionCall)
                      ? "accept" : ""}
                    text={model}
                  />
                );
                if (models[model].allowFuncCall) {
                  acc.push(
                    <MenuItem
                      key={[modIdx, "fc"].join("_")}
                      data-selected-model={model}
                      data-func-call={true}
                      icon={(model === thisModel && isFunctionCall)
                        ? "accept" : ""}
                      text={model + " (Function Call)"}
                    />
                  );
                }
                return acc;
              }, []
              )}
              {/* </MenuItem> */}
            </Menu>
            <CheckBox
              text="회사정책 질의(beta)"
              onChange={evt => {
                setIsRag(evt.target.checked);
              }}
            />

            {/* <Button
              icon="camera"
              onClick={async () => {
                const clipboardContents = await navigator.clipboard.read();
                const item = clipboardContents[0];
                if (item.types.includes("image/png")) {
                  const blob = await item.getType("image/png");
                  setFileBase64(URL.createObjectURL(blob));
                  setFileName("clipboard.png");
                  setPreviewImageIsOpen(true);
                }
              }}
            >Screenshot</Button> */}
          </FlexBox>
        </FlexBox>
      </FlexBox>

    </BusyIndicator>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(Audio));
export { Chatbot };
