var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var actions_exports = {};
__export(actions_exports, {
  SET_EMBED_URL: () => SET_EMBED_URL,
  embedOnDom: () => embedOnDom,
  getEmbedURL: () => getEmbedURL,
  setEmbedURL: () => setEmbedURL
});
module.exports = __toCommonJS(actions_exports);
var import_lib_api = require("@bsgp/lib-api");
var import_amazon_quicksight_embedding_sdk = require("amazon-quicksight-embedding-sdk");
const SET_EMBED_URL = "[QuickSight] SET_EMBED_URL";
function setEmbedURL(data) {
  return __spreadValues({
    type: SET_EMBED_URL
  }, data);
}
const getEmbedURL = (params, callback) => (dispatch) => {
  return import_lib_api.apiHub.get(
    "/g/quicksight",
    {
      dashboardAlias: params.id,
      embedType: params.type,
      sessionId: params.sessionId,
      doNotAddParamsToUrl: true
    },
    callback
  );
};
const embedOnDom = (domId) => {
  const embeddingContext = (0, import_amazon_quicksight_embedding_sdk.createEmbeddingContext)();
  const frameOptions = {
    url: "<YOUR_EMBED_URL>",
    container: "#".concat(domId),
    height: "100%",
    width: "100%",
    resizeHeightOnSizeChangedEvent: true,
    withIframePlaceholder: true,
    onChange: (changeEvent, metadata) => {
      console.log("onChange:", changeEvent, metadata);
      switch (changeEvent.eventName) {
        case "FRAME_NOT_CREATED": {
          document.getElementById(domId).innerHTML = "\uB300\uC2DC\uBCF4\uB4DC \uB85C\uB529 \uC2E4\uD328, \uC0C8\uB85C\uACE0\uCE68\uD558\uC5EC \uB2E4\uC2DC \uC2DC\uB3C4\uD574\uBCF4\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4";
          break;
        }
        case "FRAME_STARTED": {
          console.log("Do something when the experience frame starts to load.");
          break;
        }
        case "FRAME_MOUNTED": {
          console.log("Do something when the experience frame is mounted.");
          break;
        }
        case "FRAME_LOADED": {
          console.log("Do something when the experience frame is loaded.");
          break;
        }
        default: {
          console.log(
            "Unexpected changeEvent.eventName:",
            changeEvent.eventName
          );
          break;
        }
      }
    }
  };
  const contentOptions = {
    // parameters: [
    //   {
    //     Name: "country",
    //     Values: ["United States"]
    //   },
    //   {
    //     Name: "states",
    //     Values: ["California", "Washington"]
    //   }
    // ],
    locale: "ko-KR",
    // sheetOptions: {
    //   initialSheetId: "<YOUR_SHEETID>",
    //   singleSheet: false,
    //   emitSizeChangedEventOnSheetChange: false
    // },
    toolbarOptions: {
      export: true,
      undoRedo: true,
      reset: true
    },
    attributionOptions: {
      overlayContent: false
    },
    onMessage: (messageEvent, experienceMetadata) => __async(void 0, null, function* () {
      console.log("onMessage:", messageEvent, experienceMetadata);
      switch (messageEvent.eventName) {
        case "CONTENT_LOADED": {
          console.log(
            "All visuals are loaded. The title of the document:",
            messageEvent.message.title
          );
          break;
        }
        case "ERROR_OCCURRED": {
          console.log(
            "Error occurred while rendering the experience. Error code:",
            messageEvent.message.errorCode
          );
          break;
        }
        case "PARAMETERS_CHANGED": {
          console.log(
            "Parameters changed. Changed parameters:",
            messageEvent.message.changedParameters
          );
          break;
        }
        case "SELECTED_SHEET_CHANGED": {
          console.log(
            "Selected sheet changed. Selected sheet:",
            messageEvent.message.selectedSheet
          );
          break;
        }
        case "SIZE_CHANGED": {
          console.log("Size changed. New dimensions:", messageEvent.message);
          break;
        }
        case "MODAL_OPENED": {
          window.scrollTo({
            top: 0
            // iframe top position
          });
          break;
        }
        default: {
          console.log(
            "Unexpected messageEvent.eventName:",
            messageEvent.eventName
          );
          break;
        }
      }
    })
  };
  return (embedURL, options = {}) => {
    const { qsConfig, toolbarOptions, parameters } = options;
    frameOptions.url = embedURL;
    if (toolbarOptions) {
      contentOptions.toolbarOptions = __spreadValues(__spreadValues({}, contentOptions.toolbarOptions), toolbarOptions);
    }
    if (parameters) {
      console.log("parameters:", parameters);
      contentOptions.parameters = parameters;
    }
    return embeddingContext.then((qsContext) => {
      if (qsConfig.type === "EmbedConsole") {
        return qsContext.embedConsole(frameOptions, contentOptions);
      }
      return qsContext.embedDashboard(frameOptions, contentOptions);
    }).then((qsExp) => {
      window.qsExp = qsExp;
      return qsExp;
    });
  };
};
