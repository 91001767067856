var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var index_controller_exports = {};
__export(index_controller_exports, {
  onBundle: () => onBundle,
  onBundleInputChanged: () => onBundleInputChanged,
  onCloseBundle: () => onCloseBundle,
  onFetchSupplierName: () => onFetchSupplierName,
  onNaviToBundle: () => onNaviToBundle,
  onSearch: () => onSearch
});
module.exports = __toCommonJS(index_controller_exports);
var import_actions = require("../actions");
var import_ui5_lib_rc = require("ui5-lib-rc");
var import_actions2 = require("../actions");
var import_lib_date = require("@bsgp/lib-date");
var import_ui5 = require("actions/ui5");
const onFetchSupplierName = ({ props }) => (oEvent) => {
  const { supplier } = oEvent.getSource().getBindingContext().getObject();
  const { dispatch, _dispatch } = props;
  dispatch(
    (0, import_actions.fetchSupplier)(
      { id: supplier.id },
      {
        afterSucceed: (body) => {
          const suppliers = (0, import_ui5_lib_rc.tryit)(() => body.d.results);
          if (suppliers && suppliers.length === 1) {
            const supplier2 = suppliers[0];
            _dispatch(
              (0, import_actions.updateSupplierName)(
                supplier2.InternalID,
                supplier2.BusinessPartnerFormattedName
              )
            );
          }
        }
      }
    )
  );
};
const onSearch = ({ props }) => (getInputs, $skip) => (oEvent) => {
  const { dispatch, _dispatch, _state } = props;
  const { $top, queryBy } = _state;
  const oDateRange = getInputs();
  const dateFrom = oDateRange.getDateValue();
  const dateTo = oDateRange.getSecondDateValue();
  if (!dateFrom) {
    dispatch((0, import_ui5.addError)("\uAE30\uAC04\uC744 \uC120\uD0DD\uD558\uC138\uC694"));
    return;
  }
  if (!$skip) {
    _dispatch((0, import_actions2.removeAllPR)());
  }
  dispatch(
    (0, import_actions.fetchPRList)({
      dateFrom: (0, import_lib_date.getString)(dateFrom, "begin"),
      dateTo: (0, import_lib_date.getString)(dateTo, "end"),
      $top,
      $skip,
      queryBy,
      _dispatch
    })
  );
};
const onNaviToBundle = ({ props }) => (oTable) => () => {
  const { _dispatch, employee } = props;
  const selectedItems = oTable.getSelectedItems();
  const dataItems = selectedItems.map(
    (oItem) => oItem.getBindingContext().getObject()
  );
  _dispatch((0, import_actions.bundleItems)(dataItems, employee));
};
const onBundleInputChanged = ({ props }) => (fieldName, fieldType) => (oEvent) => {
  const { _dispatch } = props;
  const { newValue } = oEvent.getParameters();
  const oSource = oEvent.getSource();
  const row = oSource.getBindingContext().getObject();
  _dispatch((0, import_actions.changeBundleInput)(row.seq - 1, fieldName, newValue, fieldType));
};
const onCloseBundle = ({ props }) => (oEvent) => {
  const { _dispatch } = props;
  _dispatch((0, import_actions.closeBundle)());
  const oDialog = oEvent.getSource().getParent();
  (0, import_ui5_lib_rc.tryit)(() => oDialog.isOpen() && oDialog.close());
};
const onBundle = ({ props }) => (oTable, oPRTable) => (oEvent) => {
  const { dispatch, _dispatch } = props;
  const poItems = oTable.getModel().getData().items;
  const [isValid, poHeader] = poItems.reduce(
    (result, item) => {
      if (!result[0]) {
        return result;
      }
      if (item.bundleQuantity.number <= 0) {
        dispatch((0, import_ui5.addError)("\uC218\uB7C9\uC774 0\uACFC \uAC19\uAC70\uB098 \uC791\uC73C\uBA74 \uC548\uB429\uB2C8\uB2E4"));
        return [false, {}];
      }
      if (!item.supplier.id) {
        dispatch((0, import_ui5.addError)("\uACF5\uAE09\uC5C5\uCCB4\uB294 \uD544\uC218\uC785\uB825\uC0AC\uD56D\uC785\uB2C8\uB2E4"));
        return [false, {}];
      }
      if (!item.employee.id) {
        dispatch((0, import_ui5.addError)("\uAD6C\uB9E4\uB2F4\uB2F9\uC790\uB294 \uD544\uC218\uC785\uB825\uC0AC\uD56D\uC785\uB2C8\uB2E4"));
        return [false, {}];
      }
      const poList = result[1];
      const matchedPO = poList.find(
        (po) => po.supplier === item.supplier.id && po.buyer === item.buyer.id
      );
      if (matchedPO) {
        matchedPO.items.push(item);
      } else {
        const newPO = {
          currency: item.currency,
          supplier: item.supplier.id,
          employee: item.employee.id,
          buyer: item.buyer.id,
          items: [item]
        };
        poList.push(newPO);
      }
      return result;
    },
    [true, []]
  );
  if (isValid) {
    const oDialog = oEvent.getSource().getParent();
    const oBeginButton = oDialog.getBeginButton();
    const oEndButton = oDialog.getEndButton();
    oBeginButton.setEnabled(false);
    oEndButton.setEnabled(false);
    oTable.setBusy(true);
    dispatch(
      (0, import_actions.createPurchaseOrder)(poHeader, {
        afterSucceed: (poID) => {
          const oItems = oPRTable.getSelectedItems();
          const selectedPRList = oItems.map((oItem) => {
            const oSelectControl = oItem.getMultiSelectControl();
            if (oSelectControl) {
              oSelectControl.setEnabled(false);
            }
            return oItem.getBindingContext().getObject();
          });
          dispatch(
            (0, import_actions.cancelPR)(selectedPRList, _dispatch, () => {
              dispatch(
                (0, import_ui5.addError)({
                  type: "S",
                  message: `\uAD6C\uB9E4\uC624\uB354 ${poID}\uC774 \uC0DD\uC131\uB418\uC5C8\uC2B5\uB2C8\uB2E4`
                })
              );
              oBeginButton.setEnabled(true);
              oEndButton.setEnabled(true);
              oTable.setBusy(false);
              oDialog.isOpen() && oDialog.close();
            })
          );
        },
        always: (cbData) => {
          if (cbData === void 0) {
            oBeginButton.setEnabled(true);
            oEndButton.setEnabled(true);
            oTable.setBusy(false);
          }
        }
      })
    );
  }
};
