import { cacheRef } from "cache";

export const NEW_SYSTEM_ID = "NEW_SYSTEM_ID";
export const newSystemID = ({ systemID, system, partnerID }) => {
  return {
    type: NEW_SYSTEM_ID,
    systemID,
    system,
    partnerID
  };
};

export const SET_REQUIRE_MFA = "SET_REQUIRE_MFA";
export const setRequireMfa = () => {
  return {
    type: SET_REQUIRE_MFA
  };
};

// getModifierState('CapsLock')
export const ACTIVATED_CAPS_LOCK = "ACTIVATED_CAPS_LOCK";
export const activatedCapsLock = isActivated => {
  return {
    type: ACTIVATED_CAPS_LOCK,
    isActivated
  };
};

// sessionDisplay changing
export const DISPLAY_SESSION_INPUT = "DISPLAY_SESSION_INPUT";
export const displaySessionInput = visible => {
  return {
    type: DISPLAY_SESSION_INPUT,
    visible
  };
};

// set selected SystemID
export const SELECT_LOGIN_SYSTEM = "SELECT_LOGIN_SYSTEM";
export const selectLogInSystem = systemID => {
  return {
    type: SELECT_LOGIN_SYSTEM,
    systemID
  };
};

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export function getCurrentUser() {
  return {
    type: GET_CURRENT_USER
  };
}

export const CLOSE_ROOT_NOTICE = "CLOSE_ROOT_NOTICE";
export function closeRootNotice() {
  return {
    type: CLOSE_ROOT_NOTICE
  };
}

export const CANCEL_CHANGING_INITIAL_PASSWORD =
  "CANCEL_CHANGING_INITIAL_PASSWORD";
export function cancelChangingInitialPassword() {
  return {
    type: CANCEL_CHANGING_INITIAL_PASSWORD
  };
}

export const CHANGE_INITIAL_PASSWORD = "CHANGE_INITIAL_PASSWORD";
export function changeInitialPassword(newPassword) {
  return {
    type: CHANGE_INITIAL_PASSWORD,
    newPassword
  };
}

export const SIGN_IN = "SIGN_IN";
export function signIn(user) {
  return {
    type: SIGN_IN,
    user
  };
}

export const SIGN_OUT = "SIGN_OUT";
function signOut() {
  return {
    type: SIGN_OUT
  };
}

export const UPDATE_EXPIRES_AT = "UPDATE_EXPIRES_AT";
export function updateExpiresAt(expiresAt) {
  return {
    type: UPDATE_EXPIRES_AT,
    expiresAt
  };
}

export const UPDATE_EMPLOYEE_INFO = "[user] Update Employee Info";
export function updateEmployeeInfo(identity) {
  return {
    type: UPDATE_EMPLOYEE_INFO,
    identity
  };
}

export const SET_CURRENT_THEME = "SET_CURRENT_THEME";
export const setCurrentTheme = theme => {
  return {
    type: SET_CURRENT_THEME,
    theme
  };
};

export const SET_CURRENT_LANG = "SET_CURRENT_LANG";
export const setCurrentLang = lang => {
  return {
    type: SET_CURRENT_LANG,
    lang
  };
};

export const NEW_LOCALE_LOADED = "NEW_LOCALE_LOADED";
export const newLocaleLoaded = () => {
  return {
    type: NEW_LOCALE_LOADED
  };
};

export const STORE_PATHS_FOR_CURRENT_USER = "STORE_PATHS_FOR_CURRENT_USER";
export function storePathsForCurrentUser(paths, roles, subPathCollection) {
  return {
    type: STORE_PATHS_FOR_CURRENT_USER,
    paths,
    roles,
    subPathCollection
  };
}

export const SET_ALTER_SYSTEMS = "SET_ALTER_SYSTEMS";
export function setAlterSystems({ systems, idp, id_token }) {
  return {
    type: SET_ALTER_SYSTEMS,
    alterSystems: systems,
    idp,
    id_token
  };
}

export const GET_IDP_REDIRECTED = "GET_IDP_REDIRECTED";
export function getIdpRedirected(idpRedirected) {
  return {
    type: GET_IDP_REDIRECTED,
    idpRedirected
  };
}

export const SET_DATA_FROM_AI = "SET_DATA_FROM_AI";
export function setDataFromAi(data) {
  return {
    type: SET_DATA_FROM_AI,
    data
  };
}

export const SET_AI_SESSION_ID = "SET_AI_SESSION_ID";
export function setAiSessionId(id) {
  return {
    type: SET_AI_SESSION_ID,
    id
  };
}

export const SET_AI_MESSAGES = "SET_AI_MESSAGES";
export function setAiMessages(messages) {
  return {
    type: SET_AI_MESSAGES,
    messages
  };
}

function setCookie(cname, cvalue, exdays) {
  const now = new Date();
  now.setTime(now.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + now.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const updateCookie = (name, value, days, reloadOrNot) => {
  setCookie(name, value, days);
  setCookie("public_url", encodeURI(process.env.PUBLIC_URL), days);
  if (reloadOrNot) {
    window.location.reload();
  }
};

export const asyncSignOut = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState().user;

    cacheRef.current = null;
    dispatch(signOut());
    if (window.google) {
      window.google.accounts.id.disableAutoSelect();
    }

    if (currentUser.system && currentUser.system.useSso) {
      const stage = process.env.REACT_APP_API_STAGE_NAME;
      const baseUrl = "https://idp.bsg.support";
      const formAction = [baseUrl, `/${stage}/slo/post`].join("");
      const ssoForm = document.createElement("form");
      ssoForm.method = "POST";
      ssoForm.action = formAction;
      ssoForm.target = "_blank";
      [{ name: "open_as_new_tab", value: true }].forEach(info => {
        const elInput = document.createElement("input");
        elInput.type = "hidden";
        elInput.name = info.name;
        elInput.value = info.value;
        ssoForm.appendChild(elInput);
      });
      document.body.appendChild(ssoForm);
      ssoForm.submit();
      document.body.removeChild(ssoForm);
    }

    // updateCookie("longterm_path", "", -1, true);
  };
};
