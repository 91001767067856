import React, { useState } from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  Bar,
  Label,
  ResponsivePopover,
  ObjectStatus,
  List,
  ListItemCustom
} from "@ui5/webcomponents-react";
import { tryit } from "@bsgp/lib-core";
import { apiHub } from "@bsgp/lib-api";
import { addError } from "actions/ui5";
import { asyncSignOut, setCurrentTheme } from "actions/user";
import renderNoticeDialog from "actions/renderNoticeDialog";

import ExpiraryCountDown from "ExpiraryCountDown";
import Audio from "Audio";

import BarDesign from "@ui5/webcomponents/dist/types/BarDesign.js";
import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign.js";
import ListItemType from "@ui5/webcomponents/dist/types/ListItemType.js";
import PopoverPlacementType from
  "@ui5/webcomponents/dist/types/PopoverPlacement.js";

const HelpDialog = ({ dialogIsOpen, setDialogIsOpen, htmlContent }) => {
  const markup = { __html: htmlContent };

  return createPortal(
    <Dialog
      open={dialogIsOpen}
      stretch={true}
      className={"bsgSupportHelpDialog"}
      header={
        <Bar
          design={BarDesign.Header}
          startContent={
            <>
              <Button
                design={ButtonDesign.Negative}
                icon={"decline"}
                onClick={() => {
                  setDialogIsOpen(false);
                }}
              >
                {"닫기"}
              </Button>
              <Label wrappingType="None">도움말</Label>
            </>
          }
        />
      }
    >
      <div dangerouslySetInnerHTML={markup} />
    </Dialog>,
    document.body
  );
};

function SettingsButton(props) {
  const { history, currentUser, location, dispatch, systemVersion,
    showAiButton
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  if (!currentUser.is_authenticated) {
    return false;
  }

  return (
    (
      <div slot={props.slot}>
        <ExpiraryCountDown />
        {showAiButton === true && <Audio />}
        <Button
          id={"openSettingsBtn"}
          className={currentUser.theme}
          design={ButtonDesign.Transparent}
          title={currentUser.id}
          endIcon={"navigation-down-arrow"}
          onClick={() => {
            setMenuIsOpen(!menuIsOpen);
            setPopoverIsOpen(false);
          }}
        >
          {`${currentUser.displayName || currentUser.name}(${currentUser.id})`}
        </Button>
        <Menu
          id={"settingsMenu"}
          opener={"openSettingsBtn"}
          open={menuIsOpen}
          onItemClick={event => {
            setMenuIsOpen(false);

            const {
              url,
              showHelp,
              showAbout,
              setThemeTo,
              logOut
            } = event.detail.item.dataset;
            if (url) {
              setTimeout(() => {
                history.push(url);
              }, 0);
            } else if (showAbout === "1") {
              setPopoverIsOpen(true);
            } else if (setThemeTo === "dark") {
              dispatch(setCurrentTheme("sap_fiori_3_dark"));
            } else if (setThemeTo === "light") {
              dispatch(setCurrentTheme("sap_fiori_3"));
            } else if (setThemeTo === "dark-horizon") {
              dispatch(setCurrentTheme("sap_horizon_dark"));
            } else if (setThemeTo === "light-horizon") {
              dispatch(setCurrentTheme("sap_horizon"));
            } else if (logOut === "1") {
              dispatch(asyncSignOut());
            } else if (showHelp === "1") {
              apiHub
                .get(
                  "/g/user_help",
                  { Data: { endpoint: location.pathname } },
                  {}
                )
                .then(res => {
                  const data = tryit(() => res.data.cbData) || {};
                  if (data.type !== "S" || !data.result) {
                    throw new Error("도움말이 없습니다.");
                  }
                  const helpString = data.result;
                  const createBody = data =>
                    new DOMParser()
                      .parseFromString(data, "text/html")
                      .getElementsByTagName("body")[0];

                  if (!/<\/?[a-z][\s\S]*>/i.test(helpString)) {
                    setHtmlContent(createBody(helpString).innerText);
                  } else setHtmlContent(createBody(helpString).innerHTML);

                  setDialogIsOpen(true);
                })
                .catch(error => {
                  dispatch(addError(error.message));
                  setDialogIsOpen(false);
                });
            } else if (showHelp === "2") {
              dispatch(renderNoticeDialog(props, true, true));
            }
          }}
          onClose={() => {
            setMenuIsOpen(false);
          }}
        >
          {currentUser.idp ? (
            <></>
          ) : (
            <MenuItem
              icon={"private"}
              text={"비밀번호 변경"}
              data-url={"/resetpassword"}
            // additionalText={"비밀번호를 변경합니다"}
            />
          )}
          <MenuItem
            icon={"sys-help-2"}
            text={"도움말"}
            data-show-help={"1"}
          // additionalText={"Page 도움말을 확인합니다"}
          />
          <MenuItem
            icon={"notification-2"}
            text={"공지사항"}
            data-show-help={"2"}
          // additionalText={"Page 공지사항을 확인합니다"}
          />
          <MenuItem
            icon={"palette"}
            text={"Appearance"}
            data-show-about={"1"}
          // additionalText={"시스템 정보를 조회합니다"}
          >
            <MenuItem text={"Light"} data-set-theme-to={"light"} />
            <MenuItem text={"Dark"} data-set-theme-to={"dark"} />
            <MenuItem
              text={"Light(Horizon)"}
              data-set-theme-to={"light-horizon"}
            />
            <MenuItem
              text={"Dark(Horizon)"}
              data-set-theme-to={"dark-horizon"}
            />
          </MenuItem>
          <MenuItem
            icon={"hint"}
            text={"About"}
            data-show-about={"1"}
          // additionalText={"시스템 정보를 조회합니다"}
          />
          <MenuItem
            icon={"log"}
            text={"Log out"}
            data-log-out={"1"}
          // additionalText={"시스템 정보를 조회합니다"}
          />
        </Menu>
        <HelpDialog
          dialogIsOpen={dialogIsOpen}
          setDialogIsOpen={setDialogIsOpen}
          htmlContent={htmlContent}
        />
        {createPortal(
          <ResponsivePopover
            id={"about"}
            headerText={systemVersion || "Info"}
            placement={PopoverPlacementType.Bottom}
            opener={"openSettingsBtn"}
            open={popoverIsOpen}
            onClose={() => {
              setPopoverIsOpen(false);
            }}
          >
            <List>
              <ListItemCustom type={ListItemType.Inactive}>
                <Label
                  for={"systemId"}
                  showColon={true}
                  className="sapUiSmallMarginEnd"
                  wrappingType="None"
                >
                  {"System ID"}
                </Label>
                <ObjectStatus id={"systemId"}>
                  {[currentUser.systemID, currentUser.partnerID].join("@")}
                </ObjectStatus>
              </ListItemCustom>
              <ListItemCustom type={ListItemType.Inactive}>
                <Label
                  for={"systemName"}
                  showColon={true}
                  className="sapUiSmallMarginEnd"
                  wrappingType="None"
                >
                  {"System Name"}
                </Label>
                <ObjectStatus id={"systemName"}>
                  {[currentUser.system.name,
                    currentUser.partner.name].join("@")}
                </ObjectStatus>
              </ListItemCustom>
              <ListItemCustom type={ListItemType.Inactive}>
                <Label
                  for={"appVersion"}
                  showColon={true}
                  className="sapUiSmallMarginEnd"
                  wrappingType="None"
                >
                  {"App Version"}
                </Label>
                <ObjectStatus id={"appVersion"}>
                  {process.env.REACT_APP_RELEASE_VERSION ||
                    process.env.REACT_APP_STAGING_VERSION || ""}
                </ObjectStatus>
              </ListItemCustom>
            </List>
          </ResponsivePopover>,
          document.body
        )}
      </div>
    )
  );
}

function mapStateToProps(state) {
  const config = tryit(() => state["api-Configuration"].merged.ui.result) || {};

  return {
    systemVersion: config.SystemVersion,
    showAiButton: config.ShowAiButton,
    currentUser: state.user.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(SettingsButton));
