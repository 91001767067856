import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Toast } from "@ui5/webcomponents-react";

function MessageToast(props) {
  const { errorList } = props;
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (errorList.length === 0) {
      setShowToast(false);
    } else if (errorList.length > 0) {
      setShowToast(true);
    }
  }, [errorList.length]);

  return (
    <>
      {errorList.length > 0 && (
        <Toast
          open={showToast}
          onClose={() => {
            setShowToast(false);
          }}
          duration={5000}
        >
          {errorList[0].message}
        </Toast>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    errorList: state.ui5.errorList
  };
}

export default connect(mapStateToProps)(MessageToast);
