var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var main_exports = {};
__export(main_exports, {
  default: () => main_default
});
module.exports = __toCommonJS(main_exports);
var import_react_router_dom = require("react-router-dom");
var import_react = __toESM(require("react"));
var import_toolkit = require("@reduxjs/toolkit");
var import_react_redux = require("react-redux");
var import_lib_hoc = require("@bsgp/lib-hoc");
var import_actions = require("../actions");
var controller = __toESM(require("./index.controller"));
var ui5 = __toESM(require("./index.ui5"));
const embedder = (0, import_actions.embedOnDom)("qsEmbed");
function Main(props) {
  const {
    _state,
    dispatch,
    _dispatch,
    qsConfig
    // parameters,
    // currentUser
  } = props;
  const { embedURL, parameters, changes } = _state;
  (0, import_react.useEffect)(() => {
    dispatch(
      (0, import_actions.getEmbedURL)(qsConfig, {
        afterSucceed: (data) => {
          if (data) {
            _dispatch((0, import_actions.setEmbedURL)(data));
          }
        }
      })
    );
    return function clear() {
    };
  }, []);
  (0, import_react.useEffect)(() => {
    if (!embedURL) {
      return;
    }
    let looper;
    embedder(embedURL, {
      qsConfig,
      parameters: parameters || [],
      changes
      // [
      //   {
      //     Name: "scope",
      //     Values: [[currentUser.systemID, currentUser.partnerID].join("@")]
      //   }
      // ].concat(parameters || [])
    }).then((qsExp) => __async(this, null, function* () {
      if (changes) {
        let idx = 0;
        looper = setInterval(() => {
          qsExp.setParameters(changes.list[idx]);
          console.log("idx parameter changed:", idx);
          idx += 1;
          if (idx === changes.list.length) {
            idx = 0;
          }
        }, 5e3);
      }
    }));
    return () => {
      if (looper) {
        clearInterval(looper);
      }
    };
  }, [embedURL, qsConfig, parameters]);
  return /* @__PURE__ */ import_react.default.createElement(
    "div",
    {
      id: "qsEmbed",
      style: { height: "100%", width: "100%" },
      className: "renderJSX"
    }
  );
}
function mapStateToProps(state, ownProps) {
  return {
    render5Version: "20190808"
  };
}
var main_default = (0, import_toolkit.compose)(
  (0, import_react_redux.connect)(mapStateToProps),
  import_react_router_dom.withRouter,
  // withReducer("purchaseOrderCreation", purchaseOrderCreation),
  (0, import_lib_hoc.render5)(ui5, controller, { renderJSX: true })
)(Main);
