import React, { useState, useRef } from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { removeError } from "actions/ui5";

import {
  Bar,
  Button,
  MessageViewButton,
  ResponsivePopover,
  FlexBoxAlignItems,
  FlexBox,
  Title,
  MessageView,
  MessageItem
} from "@ui5/webcomponents-react";

import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign.js";
import PopoverPlacement from
  "@ui5/webcomponents/dist/types/PopoverPlacement.js";
import TitleLevel from "@ui5/webcomponents/dist/types/TitleLevel.js";
import ValueState from "@ui5/webcomponents-base/dist/types/ValueState.js";

function MessageIndicator(props) {
  const { errorList, dispatch } = props;

  const viewButtonStyle = {};

  if (errorList.length === 0) {
    viewButtonStyle.display = "none";
  }

  const messageViewRef = useRef(null);
  const [isOnDetailsPage, setIsOnDetailsPage] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    (
      <div slot={props.slot}>
        <MessageViewButton
          type={ValueState.Information}
          counter={errorList.length}
          style={viewButtonStyle}
          id="openPopoverBtn"
          onClick={() => {
            setIsPopoverOpen(!isPopoverOpen);
          }}
        />
        {createPortal(
          <ResponsivePopover
            opener="openPopoverBtn"
            open={isPopoverOpen}
            placement={PopoverPlacement.Top}
            className="contentPartNoPadding headerPartNoPadding"
            style={{
              maxWidth: "30rem",
              minWidth: "20rem"
            }}
            header={
              <Bar
                startContent={
                  <FlexBox alignItems={FlexBoxAlignItems.Center}>
                    {isOnDetailsPage && (
                      <Button
                        design={ButtonDesign.Transparent}
                        icon="slim-arrow-left"
                        onClick={() => {
                          setIsOnDetailsPage(false);
                          messageViewRef.current.navigateBack();
                        }}
                        style={{
                          marginInline: "0 0.5rem"
                        }}
                      />
                    )}
                    <Title
                      level={TitleLevel.H5}
                      wrappingType="None"
                    >Messages</Title>
                  </FlexBox>
                }
                endContent={
                  <FlexBox alignItems={FlexBoxAlignItems.End}>
                    <Button
                      design={ButtonDesign.Transparent}
                      icon="delete"
                      onClick={() => {
                        dispatch(removeError());
                        setIsPopoverOpen(false);
                        setIsOnDetailsPage(false);
                        messageViewRef.current.navigateBack();
                      }}
                      style={{
                        marginInline: "0 0.5rem"
                      }}
                    />
                    <Button
                      design={ButtonDesign.Transparent}
                      icon="decline"
                      onClick={() => {
                        setIsPopoverOpen(false);
                        setIsOnDetailsPage(false);
                        messageViewRef.current.navigateBack();
                      }}
                      style={{
                        marginInline: "0 0.5rem"
                      }}
                    />
                  </FlexBox>
                }
              />
            }
            onClose={() => {
              setIsPopoverOpen(false);
            }}
          >
            <MessageView
              ref={messageViewRef}
              showDetailsPageHeader={false}
              groupItems={false}
              onItemSelect={() => {
                setIsOnDetailsPage(true);
              }}
            >
              {errorList.map((msg, mIdx) => (
                <MessageItem
                  key={mIdx}
                  titleText={msg.message}
                  subtitleText={msg.description}
                  type={msg.type}
                // groupName={`Group${index}`}
                >
                  {msg.description}
                </MessageItem>
              ))}
            </MessageView>
          </ResponsivePopover>,
          document.body
        )}
      </div>
    )
  );
}

function mapStateToProps(state) {
  return {
    errorList: state.ui5.errorList
  };
}

export default connect(mapStateToProps)(MessageIndicator);
