/**
 * @overview Convert UOM code to readable text
 * @description API 요청 및 Business Object 마다 다르게 표기된 UOM 을 컨버전하고,
 * 한글로 읽을 수 있으며, UOM 별 소수점 자리를 지정함.
 */

/**
 * 유닛 코드를 읽기쉬운 텍스트 및 단위로 반환하고, 표기 소수점 자리수를 표현합니다.
 * @param {*} unitCode UOM CODE
 */

export const unitCodeToKor = unitCode => {
  const uom = {
    ZSH: { text: "ZSH", dp: 3 },
    XRO: { text: "롤", dp: 3 },
    XPR: { text: "용기", dp: 0 },
    XBE: { text: "번들", dp: 0 },
    XBX: { text: "상자", dp: 0 },
    GRM: { text: "g", dp: 3 },
    HUR: { text: "h", dp: 3 },
    KGM: { text: "kg", dp: 3 },
    MIN: { text: "분", dp: 3 },
    MTQ: { text: "m³", dp: 3 },
    MTR: { text: "m", dp: 3 },
    SEC: { text: "s", dp: 3 },
    TNE: { text: "mt", dp: 3 },
    EA: { text: "개", dp: 0 },
    SET: { text: "세트", dp: 0 },
    XST: { text: "jo", dp: 0 },
    MLT: { text: "ml", dp: 0 },
    MTK: { text: "m²", dp: 3 },
    PR: { text: "pair", dp: 0 },
    YRD: { text: "yard", dp: 3 },
    ZBO: { text: "본", dp: 0 }
  };

  return uom[unitCode] ? uom[unitCode] : unitCode;
};
