import { tryit } from "ui5-lib-rc";

// const leakArguments = function(args, excludeLength) {
//   const elen = excludeLength || 0;
//   return Array.prototype.slice.call(args, elen);
// };

export const convURL = {
  custom: function(entity, options) {
    return dispatch => {
      return dispatch(convertUrl({ entity, type: "custom" }, options));
    };
  },
  datasource: function(entity, options) {
    return dispatch => {
      return dispatch(convertUrl({ entity, type: "datasource" }, options));
    };
  },
  report: function(entity, options) {
    return dispatch => {
      return dispatch(convertUrl({ entity, type: "report" }, options));
    };
  },
  reportANA: function(entity, options) {
    return dispatch => {
      return dispatch(convertUrl({ entity, type: "reportANA" }, options));
    };
  },
  reportCC: function(entity, options) {
    return dispatch => {
      return dispatch(convertUrl({ entity, type: "reportCC" }, options));
    };
  }
};

function convertUrl({ entity, type }, params) {
  return (dispatch, getState) => {
    const user = getState().user.currentUser;

    const nParams = params || {};
    if (!nParams["sap-language"]) {
      nParams["sap-language"] = "ko"; // user.lang || "en";
    }
    if (!nParams.$format) {
      nParams.$format = "json";
    }
    if (!nParams.$inlinecount) {
      nParams.$inlinecount = "allpages";
    }

    if (nParams.ignoreQueryOptions) {
      nParams.ignoreQueryOptions.forEach(queryOption => {
        delete nParams[queryOption];
      });
      delete nParams.ignoreQueryOptions;
    }

    const baseURL = [
      "https://",
      user.pid,
      ".",
      tryit(() => {
        switch (type) {
          case "report":
          case "reportCC":
            return process.env.REACT_APP_BYD_REPORT_ODATA_PATH;
          case "reportANA":
            return process.env.REACT_APP_BYD_REPORT_ODATA_PATH_ANA;
          case "datasource":
            return process.env.REACT_APP_BYD_DATASOURCE_ODATA_PATH;
          case "custom":
          default:
            return process.env.REACT_APP_BYD_CUSTOM_ODATA_PATH;
        }
      }),
      "/",
      entity
    ].join("");

    const splitters = {
      $expand: ",",
      $select: ",",
      $filter: " ",
      $orderby: ","
    };
    const splitter = key => {
      return splitters[key] || "";
    };

    const stringParams = Object.keys(nParams)
      .map(key => {
        const value = nParams[key];
        let part = "";

        part += key + "=";

        Array.isArray(value)
          ? (part += value.filter(item => item).join(splitter(key)))
          : (part += value);

        return part;
      })
      .join("&");

    return `${baseURL}?${stringParams}`;
  };
}
