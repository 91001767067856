const routes = [
  {
    path: "/ai",
    component: () => import("components/AI"),
    checkAuth: false
  },
  {
    path: "/resetpassword",
    component: () => import("components/ResetPassword"),
    checkAuth: false
  },
  {
    path: "/qsdsi/:qsAlias",
    component: () => import("@bsgp/quicksight-dashboard"),
    additionalProps: {
      // qsConfig: {
      //   id: "InterfaceLog",
      //   type: "EmbedDashboard"
      // }
    }
  },
  {
    exact: false,
    path: "/prm",
    component: () => import("@bsgp/dna-prm")
  },
  {
    exact: false,
    path: "/gc",
    component: () => import("@bsgp/gc-pm")
  },
  {
    exact: false,
    path: "/etl",
    component: () => import("@bsgp/se11")
  },
  {
    exact: false,
    path: "/ccs",
    component: () => import("@bsgp/spark")
  },
  {
    exact: false,
    path: "/srm",
    component: () => import("@bsgp/srm")
  },
  {
    exact: false,
    path: "/users",
    component: () => import("@bsgp/users")
  },
  {
    exact: false,
    path: "/helpeditor",
    component: () => import("@bsgp/help-editor")
  },
  {
    exact: false,
    path: "/apihub",
    component: () => import("@bsgp/api-hub")
  },
  {
    exact: false,
    path: "/sso-metadata",
    component: () => import("@bsgp/sso-metadata")
  }
];
export default routes;
