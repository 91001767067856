import produce from "immer";
import {
  UPDATE_DATA,
  DOING_VALIDATION,
  DONE_VALIDATION
} from "actions/adir";

const initialState = {
  partners: [],
  systems: [],
  users: [],
  roles: [],
  paths: [],
  userGroups: []
};

function backup(draft, key) {
  // backup using latest data
  draft[key + "s-bak"] = produce(draft[key + "s"], () => {});
}

function convSubPathsOut(subPaths, allPaths) {
  return subPaths.map(path => {
    if (path.id) {
      const fullPath = allPaths.find(pathAll => pathAll.id === path.id);
      return {
        ...path,
        ...fullPath,
        ...(path.subPaths && {
          subPaths: convSubPathsOut(path.subPaths, allPaths)
        })
      };
    } else {
      return {
        ...path,
        ...(path.subPaths && {
          subPaths: convSubPathsOut(path.subPaths, allPaths)
        })
      };
    }
  });
}

export default function(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case DOING_VALIDATION:
        draft.validation = {};
        draft.validation.hasError = false;
        draft.validation.error = null;
        draft.validation.done = false;
        break;
      case DONE_VALIDATION:
        draft.validation = {};
        draft.validation.hasError = action.hasError;
        draft.validation.error = action.error;
        draft.validation.done = true;
        break;
      case UPDATE_DATA:
        const data = (function() {
          const key = action.key;
          if (draft[key + "s"] === undefined) {
            draft[key + "s"] = [];
          }
          if (action.prevData) {
            draft[key + "s"] = draft[key + "s"].filter(
              dat => dat.id !== action.prevData.id
            );
          }
          const data = draft[key + "s"].find(dat => dat.id === action.data.id);
          if (data) {
            return data;
          } else {
            const data = {};
            draft[key + "s"].push(data);
            return data;
          }
        })();

        Object.keys(action.data).forEach(key => {
          data[key] = action.data[key];
        });

        if (action.key === "path") {
          if (data.subPaths) {
            data.subPaths = convSubPathsOut(
              data.subPaths,
              draft[action.key + "s"]
            );
          }
        }
        break;
      default:
        break;
    }

    if (action && action.key) {
      backup(draft, action.key);
    }
  });
}
